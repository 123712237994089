import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { UserDomain } from "../../domain/user-domain";
import { html, nothing } from "lit";
import { links } from "./index";

export class PageUserList extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminListPage) {
  canView = true;
  canEdit = true;
  canDestroy = true;
  canAdd = true;
  searchable = true;
  title = 'User List';
  topLinks = links

  viewUrl(obj) {
    return `/tenants/users/${obj.id}/view`;
  }
  editUrl(obj) {
    return `/tenants/users/${obj.id}`;
  }
  newUrl() {
    return `/tenants/users/new`;
  }
  destroyUrl(obj) {
    return `/tenants/users/${obj.id}/destroy`;
  }

  constructor() {
    super(new UserDomain());
  }

  get columns() {
    return [
      {name: 'Firstname', field: 'firstname', sortable: true, filterable: false, searchable: true, link: true},
      {name: 'Lastname', field: 'lastname', sortable: true, filterable: false, searchable: true, link: true},
      {name: 'Organisation', field: 'organisation_id', sortable: true, filterable: false, searchable: true, classList: {small: true}},
      {name: 'Email', field: 'email', sortable: true, filterable: false, searchable: true},
      {name: 'Roles', field: null, render: (row) => row?.roles?.map((r) => r.name).join(', '), classList: {small: true}},
      {name: 'Risk', field: 'absence_prediction_level_score', sortable: false, render: (row) => {
        let color = row.absence_prediction_level || 'var(--color-text-100)';
        if (color === 'red') color = 'var(--color-accent-200)';
        if (color === 'yellow') color = 'var(--color-accent-100)';
        if (color === 'green') color = 'var(--color-accent-400)';
        return html`
          <svg-icon icon="account" style="--icon-fill-color: ${color}; --icon-size: 22px;"></svg-icon>
        `;
      }},
      {name: 'Created', field: 'created_at', sortable: true, filterable: false, searchable: false, render: (obj) => {
        if (!obj.created_at) return nothing;
        return html`${(new Date(obj.created_at)).format({mode: 'format'})}`;
      }},
    ];
  }
}
